module.exports.commonCart = `{
    id
    pincode
    prices {
      grand_total {
        value
        currency
      }
      subtotal_including_tax {
        value
        currency
      }
      discount {
        amount {
          value
          currency
        }
      }
    }
    items {
      uid
      id
      quantity
      prices {
        total_item_discount {
          value
          currency
        }
        row_total {
          value
          currency
        }
        price {
          value
          currency
        }
        discounts {
          label
          amount {
            value
            currency
          }
        }
      }
      product {
        sku
        name
        image {
          url
          label
        }
        price {
          regularPrice {
            amount {
              value
              currency
            }
          }
        }
      }
    }
   applied_coupons {
    code
  }
  email
  billing_address {
    firstname
    lastname
    company
    street
    city
    region{
      code
      label
    }
    postcode
    telephone
    country{
      code
      label
    }
  }
  shipping_addresses {
    firstname
    lastname
    company
    street
    city
    region {
      code
      label
    }
    postcode
    telephone
    country {
      code
      label
    }
    available_shipping_methods{
      carrier_code
      carrier_title
      method_code
      method_title
      amount {
        value
        currency
      }
    }
      selected_shipping_method {
        carrier_code
        carrier_title
        method_code
        method_title
        amount {
          value
          currency
        }
      }
    }
    available_payment_methods {
      code
      title
    }
    selected_payment_method {
      code
    }
  }
  `
module.exports.getAddress = (address) => {
  if (!address) {
      return
  }
  let addr = [];
  if (address.street) {
      addr.push(address.street);
  }
  if (address.city) {
      addr.push(address.city)
  }
  if (address.region) {
      addr.push(address.region.region)
  }
  if (address.postcode) {
    addr.push(address.postcode)
}
  if (address.country_code) {
      addr.push(address.country.code )
  }
  
  return <p>
      {addr.join(', ')}
  </p>
}
module.exports.getAddresStr = (address) => {
  if (!address) {
      return
  }
  let addr = [];
  if (address.street) {
      addr.push(address.street);
  }
  if (address.city) {
      addr.push(address.city)
  }
  if (address.region) {
      addr.push(address.region.region)
  }
  if (address.postcode) {
    addr.push(address.postcode)
}
  if (address.country_code) {
      addr.push(address.country.code )
  }
  
  return addr.join(', ')
}