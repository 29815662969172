import client from "../graphqlClient";
import { ADD_TO_CART, CREATE_GUEST_EMPTY_CART, LOGGED_IN_USER_CART_ID, CART_DATA, REMOVE_ITEM_FORM_CART,
MERGE_CARTS, APPLY_COUPAN_TO_CART, REMOVE_COUPAN_FROM_CART, ADD_TO_CART_WITH_CUSTOM_PRICE, UPDATE_CART} from "../gql/cart/cart.gql"

// export const guestMiniCart = async (variables) => {
//   const options = {
//     query: GUEST_MINI_CART,
//     variables: variables,
//   };

//   try {
//     const response = await client.query(options);
//     return response;
//   } catch (error) {
//     return error
//   }
// };

// export const loggedInUserMiniCart = async (variables) => {
//   const options = {
//     query: LOGGED_IN_USER_MINI_CART,
//     variables: variables,
//     context: {
//       headers: {
//         Authorization: `Bearer ${variables.token}`,
//       }
//   }
//   };
//   try {
//     const response = await client.query(options);
//     return response;
//   } catch (error) {
//     return error
//   }
// };
export const getCartDataFromServer = async (variables) => {
  let options = {
    query: CART_DATA,
    variables: variables,
  };
  if(variables?.token){
    options = {
      ...options, context: {
        headers: {
          Authorization: `Bearer ${variables.token}`,
        }
    },
    fetchPolicy: 'no-cache',
    }
  }
  try {
    const response = await client.query(options);
    return response;
  } catch (error) {
    return error
  }
};
export const addToCart = async (variables) => {
  let options = {
    mutation: ADD_TO_CART,
    variables: variables,
  };
  if(variables?.token){
    options = {
      ...options, context: {
        headers: {
          Authorization: `Bearer ${variables.token}`,
        }
    },
    fetchPolicy: 'no-cache',
    }
  }
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
export const addToCartWithPrice = async (variables) => {
  let options = {
    mutation: ADD_TO_CART_WITH_CUSTOM_PRICE,
    variables: variables,
  };
  if(variables?.token){
    options = {
      ...options, context: {
        headers: {
          Authorization: `Bearer ${variables.token}`,
        }
    },
    fetchPolicy: 'no-cache',
    }
  }
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
export const removeItemFromCart = async (variables) => {
  let options = {
    mutation: REMOVE_ITEM_FORM_CART,
    variables: variables,
  };
  if(variables?.token){
    options = {
      ...options, context: {
        headers: {
          Authorization: `Bearer ${variables.token}`,
        }
    },
    fetchPolicy: 'no-cache',
    }
  }
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
export const createGuestEmptyCart = async (variables) => {
  const options = {
    mutation: CREATE_GUEST_EMPTY_CART,
    variables: variables,
  };
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
export const loggedInUserCartId = async (variables) => {
  const options = {
    query: LOGGED_IN_USER_CART_ID,
    variables: variables,
    context: {
      headers: {
        Authorization: `Bearer ${variables.token}`,
      }
  }
  };
  try {
    const response = await client.query(options);
    return response;
  } catch (error) {
    return error
  }
};
export const mergeCarts = async (variables) => {
  const options = {
    mutation: MERGE_CARTS,
    variables: variables,
    context: {
      headers: {
        Authorization: `Bearer ${variables.token}`,
      }
  }
  };
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
export const applyCouponToCart = async (variables) => {
  let options = {
    mutation: APPLY_COUPAN_TO_CART,
    variables: variables,
  };
  if(variables?.token){
    options = {
      ...options, context: {
        headers: {
          Authorization: `Bearer ${variables.token}`,
        }
    }
    }
  }
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
export const removeCouponFromCart = async (variables) => {
  let options = {
    mutation: REMOVE_COUPAN_FROM_CART,
    variables: variables,
  };
  if(variables?.token){
    options = {
      ...options, context: {
        headers: {
          Authorization: `Bearer ${variables.token}`,
        }
    }
    }
  }
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
export const updateCart = async (variables) => {
  let options = {
    mutation: UPDATE_CART,
    variables: variables,
  };
  if(variables?.token){
    options = {
      ...options, context: {
        headers: {
          Authorization: `Bearer ${variables.token}`,
        }
    },
    fetchPolicy: 'no-cache',
    }
  }
  try {
    const response = await client.mutate(options);
    return response;
  } catch (error) {
    return error
  }
};
