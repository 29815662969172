import { gql } from "graphql-tag";
import { commonCart } from "../common";



export const CART_DATA = gql`
query($cartId: String!) {
    cart(cart_id: $cartId) ${commonCart}
  }
`;
// export const GUEST_MINI_CART = gql`
// query($cartId: String!) {
//     cart(cart_id: $cartId) {
//       id
//       items {
//         id
//         quantity
//         product {
//           sku
//           name
//           price {
//             regularPrice {
//               amount {
//                 value
//                 currency
//               }
//             }
//           }
//         }
//       }
//       prices {
//         grand_total {
//           value
//           currency
//         }
//       }
//     }
//   }
// `;
// export const LOGGED_IN_USER_MINI_CART = gql`
// query($cartId: String!) {
//     cart(cart_id: $cartId) {
//       id
//       items {
//         id
//         quantity
//         product {
//           sku
//           name
//           price {
//             regularPrice {
//               amount {
//                 value
//                 currency
//               }
//             }
//           }
//         }
//       }
//       prices {
//         grand_total {
//           value
//           currency
//         }
//       }
//     }
//   }
// `;

export const LOGGED_IN_USER_CART_ID = gql`
query GetCartId {
  customerCart {
    id
  }
}
`;
export const CREATE_GUEST_EMPTY_CART = gql`
mutation CreateGuestCart {
  createEmptyCart
}
`;
export const ADD_TO_CART = gql`
  mutation AddToCart($cartId: String!, $sku: String!, $quantity: Float!) {
    addSimpleProductsToCart(
      input: {
        cart_id: $cartId
        cart_items: [
          {
            data: {
              quantity: $quantity
              sku: $sku
            }
          }
        ]
      }
    ) {
      cart ${commonCart}
    }
  }
`;
export const ADD_TO_CART_WITH_CUSTOM_PRICE = gql`
  mutation addSimpleProductsToCartCustomPrice($pincode: String!, $cartId: String!, $sku: String!, $quantity: Float!, $custom_price: Float!) {
    addSimpleProductsToCartCustomPrice(
      input: {
        cart_id: $cartId
        pincode: $pincode
        cart_items: [
          {
              quantity: $quantity
              sku: $sku
              custom_price: $custom_price
            
          }
        ]
      }
    )  ${commonCart}
    
  }
`;
export const REMOVE_ITEM_FORM_CART = gql`
mutation RemoveItemFromCart($cartId: String!, $cartItemId: Int!) {
  removeItemFromCart(
    input: {
      cart_id: $cartId,
      cart_item_id: $cartItemId
    }
  ) {
    cart ${commonCart}
  }
}


`;
export const MERGE_CARTS = gql`
  mutation MergeCarts($sourceCartId: String!, $destinationCartId: String!) {
    mergeCarts(sourceCartId: $sourceCartId, destinationCartId: $destinationCartId) {
      id
      items {
        id
        quantity
      }
    }
  }
`;

export const APPLY_COUPAN_TO_CART = gql`mutation($cartId: String!, $couponCode: String!) {
  applyCouponToCart(
    input: {
      cart_id: $cartId
      coupon_code: $couponCode
    }
  ) {
    cart${commonCart}
  }
}
`;


export const REMOVE_COUPAN_FROM_CART = gql`
  mutation RemoveCouponFromCart($cartId: String!) {
    removeCouponFromCart (
      input:{ cart_id: $cartId }
  ) 
  {
     cart ${commonCart}
  }
}`
export const UPDATE_CART = gql`
  mutation ($cartId: String!, $cartItems: [CartItemUpdateInput!]!) {
    updateCartItems(
      input: {
        cart_id: $cartId
        cart_items: $cartItems
      }
    ) {
      cart ${commonCart}
    }
  }
`;

;